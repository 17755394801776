.container-profile-seller .nav-pills .nav-link.active,
.container-profile-seller .nav-pills .show>.nav-link {
    color: black;
    background-color: transparent;
}

.container-profile-seller .nav-pills .nav-link {
    color: gray;
}

.container-profile-seller .nav-outline {
    border: 1px solid gray;
}

.container-profile-seller .btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;
}

.container-profile-seller .btn-toggle:hover,
.container-profile-seller .btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    background-color: transparent;
}

.container-profile-seller .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
    transform: rotate(90deg);
}

.container-profile-seller .btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
}

.container-profile-seller .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(270deg);
}

.container-profile-seller .btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
    color: gray;
}

.container-profile-seller .btn-toggle-nav a:hover,
.container-profile-seller .btn-toggle-nav a:focus {
    color: black;
    background-color: transparent;
}


.container-profile-seller a[aria-expanded="false"] .label-sidebar {
    color: grey;
}

.container-profile-seller a[aria-expanded="true"] .label-sidebar:focus,
.container-profile-seller a[aria-expanded="true"] .label-sidebar:hover {
    color: black;
}

.container-profile-seller table th {
    color: grey !important;
}


.container-profile-seller .btn-search {
    background-color: white;
    color: gray;
    font-size: 14px;
    border: 0;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    width: 50px;
    margin: auto;
    /* margin-right: 20px; */
}



.container-profile-seller .container-nav-pills .nav-pills .nav-link.active,
.container-profile-seller .container-nav-pills .nav-pills .show>.container-nav-pills .nav-link {
    color: #DB3022;
    background-color: transparent;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 5px solid #DB3022;
    border-radius: 0%;
}

.container-profile-seller .container-nav-pills .nav-pills .nav-link {
    color: gray;

}

.container-profile-seller .container-nav-pills .nav-outline {
    border: 1px solid gray;
}


/* .container-profile-seller  .btn:hover,
.container-profile-seller  .btn:hover::after,
.container-profile-seller  .btn:focus,
.container-profile-seller  .btn:active
 {
    border :none;
} */

.container-profile-seller .nav-link {
    cursor: pointer;
}


.container-profile-seller .ico-profile {
    background-color: #456BF3;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.container-profile-seller .ico-profile img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}


.container-profile-seller .ico-product {
    background-color: #F36F45;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.container-profile-seller .ico-product img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

.container-profile-seller .ico-order {
    background-color: #F3456F;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.container-profile-seller .ico-order img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

.container-profile-seller .logo-profile-middle img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit:cover;
} 


@media only screen and (max-width: 576px) {
   
.profile-seller-card { 
    margin-top: 50px;
    margin-bottom: 50px;
}

.logo-profile-middle {
    float: left;
    width: 50%;
}

.edit-profile-middle{
    float: left;
  width: 50%;
}
}
  