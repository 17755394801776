.container-profile-seller .logo-profile-big img {
    height: 110px;
    width: 110px;
    border-radius: 50%;
    object-fit:cover;
} 


.container-profile-seller .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
/*   
  .container-profile-seller .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  } */
  
  .container-profile-seller .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }