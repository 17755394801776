
.product-swipers .swiper {
  width: 100%;
  height: 100%;
}

.product-swipers .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.product-swipers .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-swipers .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.product-swipers .swiper-slide {
  background-size: cover;
  background-position: center;
}

.product-swipers .mySwiperDisplay {
  height: 80%;
  width: 100%;
}

.product-swipers .mySwiperTumbnails {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.product-swipers .mySwiperTumbnails .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.product-swipers .mySwiperTumbnails .swiper-slide-thumb-active {
  opacity: 1;
}

.product-swipers .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

  .btn-plus {
  height: 36px;
  width: 36px;
  border: 1px solid #D4D4D4;
  background-color: white;
}

 .btn-min {
  height: 36px;
  width: 36px;
  border: 1px solid #D4D4D4;
  background-color: white;
}

.product-detail-page .link-redirect {
  cursor:pointer;
}

.btn .btn-outline:hover {
  color: var(--bs-danger) !important;
  border: 1px solid var(--bs-danger) !important;
}

.btn-check:checked+.btn {
  color: white !important;
}

.btn .btn-circle {
  border: 1px solid black;
}

/* .product-detail-page  .btn-outline:hover,
.product-detail-page   .btn-outline:focus,
.product-detail-page    .btn-outline:active, 
.product-detail-page    .btn-outline:active:focus:not(:disabled):not(.disabled),
.product-detail-page    .btn:focus, 
.product-detail-page    .btn:active, 
.product-detail-page    .btn:hover {
      box-shadow: none!important;
      outline: 0;
      background-color:transparent;
  }
   */



@media only screen and (max-width: 576px) {
  .product-detail-page {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .div-btn-product {
    margin-top: 3%;
  }
}